<template>
  <div>性能测试用例</div>
</template>

<script>
export default {
  name: "PerformanceTestCases"
}
</script>

<style scoped>

</style>