<template>
<div>
<!--  <Login></Login>-->
  <router-view/>
</div>

</template>

<style>

</style>
<script>
import Login from "@/components/Login";
export default {
  components: {Login}
}
</script>