<template>
  <div>性能测试</div>
</template>

<script>
export default {
  name: "PerformanceTest"
}
</script>

<style scoped>

</style>